@import "variables";
@import "mixins";

.mielie-campaign {
    .headline-section {
        background-color: #a7cde2;
        background-image: url("/static/img/Mielies.jpg");
        background-position: 50% 20px;
    }
    .how-to-use-maps-container {
        text-align: center;
    }
    .map {
        min-width: 0;
        width: 100%;
    }
}

.mielies-campaign.headline-section {
    background-color: #FAFAFA;
    background-image: url("/static/img/mielies-cover.jpg");
    background-position: 50% 150px;
    background-size: 100%;
    min-height: 600px;
    h1.home-headline {
        width: 100%;
        text-align: center;
        margin-bottom: 15px;
        strong {
            &:after {
                bottom: -15px;
                right: 0;
                left: -5px;
                height: 9px;
                background-size: 100%;
                width: 100%;
                background: url(/static/img/mielies-line.svg);
                background-repeat: no-repeat;
            }
        }
    }
    h2 {
        text-align: center;
        font-size: 24px;
    }
    .cta {
        margin: 0 auto;
        display: block;
        width: 232px;
        padding: 12px;
        font-size: 16px;
    }
}

.mielies-b-campaign.headline-section {
    background-image: radial-gradient(50% 100%, #2F4566 0%, #192943 100%);
    background-position: 0;
    min-height: 500px;
    h1.home-headline {
        width: 100%;
        text-align: center;
        margin-bottom: 15px;
        color: #fff;
        strong {
            &:after {
                bottom: -15px;
                right: 0;
                left: -5px;
                height: 9px;
                background-size: 100%;
                width: 100%;
                background: url(/static/img/mielies-line.svg);
                background-repeat: no-repeat;
            }
        }
    }
    h2 {
        color: #fff;
        text-align: center;
        font-size: 24px;
    }
    .mielie-image {
        margin: 40px auto;
        display: block;
    }
    .cta {
        margin: 0 auto;
        display: block;
        width: 232px;
        padding: 12px;
        font-size: 16px;
    }
}

.right.nav-cta-container {
    padding-top: 5px;
    .cta {
        padding: 10px;
        font-size: 14px;
    }
}

.campaign-form-block {
    margin: 50px auto;
    width: 350px;
    position: relative;
    form {
        .form-header {
            text-align: center;
            font-size: 24px;
            margin-bottom: 3px;
        }
        .form-sub-header {
            text-align: center;
            font-size: 14px;
        }
        .contact-form {
            width: 100%;
            background-color: #fff;
            padding-bottom: 45px;
        }
    }
}

.farmers-we-work-with {
    background-image: url(/static/img/FarmerPics.png);
    background-size: contain;
    width: 150px;
    height: 69px;
    background-repeat: no-repeat;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -42px;
    margin: 0 auto;
    z-index: 1000;
}

.header-section.campaign-form-header {
    z-index: 100;
    position: relative;
    a {
        margin: 0 auto;
        display: block;
        width: 127px;
    }
}

.campaign-form-background {
    background: center url(/static/img/FormPattern.svg) repeat;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
}

.landing-page,
.citrus-campaign {
    padding-top: 65px;
    #results-anchor,
    #sien-wat-anchor {
        position: relative;
        top: -70px;
    }
    #sien-wat-anchor {
        top: -55px;
    }
    .header-section {
        @include tile-box-shadow;
        height: 65px;
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        z-index: 1000;
        background-color: #142945;
        .nav {
            display: inline-block;
            margin-left: 240px;
            a {
                color: white;
                text-decoration: none;
                padding: 15px;
            }
        }
        a.questions-link {
            @include border-radius(5px);
            border: 2px solid white;
            color: white;
            text-decoration: none;
            padding: 6px 15px;
            margin: -2px 0 0 10px;
            float: right;
            font-size: $small-text;
            &:hover {
                cursor: pointer;
            }
        }
    }
    .headline-section {
        .home-headline {
            float: left;
            width: 60%;
            text-align: left;
            letter-spacing: 0;
            margin-top: 30px;
            strong {
                white-space: nowrap;
                position: relative;
                &:before,
                &:after {
                    left: 0;
                    content: "";
                    position: absolute;
                }
                &.has-handwriting:before {
                    top: -40px;
                    right: 10px;
                    height: 45px;
                    background: right url("/static/img/en-ander-gewasse.svg") no-repeat;
                }
                &:after {
                    bottom: -15px;
                    right: 0;
                    height: 20px;
                    background: center url("/static/img/underline.svg") no-repeat;
                }
            }
        }
        .container.top {
            max-width: 1300px;
            width: 90%;
        }
        .form-block {
            float: right;
        }
        .contact-form.tile {
            background-color: white;
            border: 0;
        }
    }
    .why-section {
        .why-block {
            float: left;
            width: 40%;
        }
        .health-map {
            float: right;
            vertical-align: top;
            clear: none;
            width: 60%;
            position: relative;
        }
        .about-me-text span.green-text {
            font-size: 1.5em;
            font-weight: 600;
            line-height: 0.5;
        }
    }
    .scewed-edge {
        @include scewed-edge(white);
    }
    .container.why {
        z-index: 2;
    }
    .how-section {
        padding-top: 60px;
        padding-bottom: 0;
        background: #142945 url("/static/img/universe-pattern.svg") repeat;
        .video {
            @include border-radius(6px);
            width: 650px;
            height: 366px;
            border: 3px solid white;
            margin: 0 auto;
            iframe {
                display: block;
                width: 100%;
                height: 100%;
            }
        }
        .how-block {
            margin-top: 30px;
            .video {
                position: relative;
                img.kyk-my {
                    position: absolute;
                    left: -160px;
                    bottom: 40px;
                }
            }
            #down-arrow {
                margin-bottom: 30px;
            }
            #map-prototype {
                margin-bottom: -40px;
            }
        }
    }
    .wat-kos-dit-section {
        background-color: #F4F6F8;
        padding-top: 80px;
        padding-bottom: 60px;
        text-align: center;
        .subtext {
            margin-top: 0;
        }
        .price-panel {
            @include tile-box-shadow;
            @include border-radius(6px);
            max-width: 820px;
            min-height: 330px;
            margin: 0 auto;
            background-color: white;
            position: relative;
            .left {
                @include border-radius(6px 0 0 6px);
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                background-color: #F4F4F4;
                width: 250px;
                padding: 15px;
                text-align: center;
                .icon {
                    margin-top: -35px;
                }
                h4 {
                    font-weight: bold;
                    margin-bottom: -3px;
                    line-height: 1.1em;
                }
                hr {
                    @include border-radius(2px);
                    width: 30px;
                    border: 2px solid #5D6E80;
                    margin: 20px auto;
                }
                .caption {
                    margin-bottom: 12px;
                    margin: 5px;
                }
                #per-day {
                    vertical-align: bottom;
                }
                .cta {
                    position: absolute;
                    bottom: 15px;
                    left: 15px;
                    right: 15px;
                }
            }
            .right {
                padding: 15px 15px 15px 270px;
                text-align: left;
                width: 100%;
                h4 {
                    color: #5D6E80;
                    font-size: 1.2em;
                    font-weight: 400;
                    margin-top: 0;
                }
                .list-item .about-me-text span {
                    color: #5D6E80;
                    font-size: 0.9em;
                }
            }
        }
    }
    .testimonials-section {
        background-color: white;
    }
    .partners-section {
        background: #142945;
    }
    .footer {
        background: #142945 center url("/static/img/universe-pattern.svg") repeat;
        * {
            color: white;
        }
    }
}

.landing-page.home {
    .headline-section .home-headline {
        strong:before {
            display: none;
        }
    }
}

.german {
    .contact-form.tile {
        padding-bottom: 15px;
    }
    .headline-section .home-headline {
        margin-top: 0;
    }
    .price-panel .left .cta {
        padding-left: 10px;
        padding-right: 10px;
    }
}

.citrus-campaign {
    .headline-section {
        background-image: url("/static/img/background-citrus.jpg");
        background-position: 50% 0;
        .home-headline strong:before {
            display: none;
        }
    }
}

.irricheck {
    color: $irricheck-text-colour;
    .header-section {
        padding: 18px 15px;
        height: 72px;
        background-color: $irricheck-primary-colour;
        .irricheck-logo {
            width: 175px;
            margin-top: -15px;
        }
        .farmpin-wordmark {
            top: 24px;
        }
        .nav {
            margin-left: 200px;
        }
    }
    .headline-section {
        background: white center center url('/static/img/irricheck-banner.jpg') no-repeat;
        background-size: cover;
        min-height: 555px;
        .container.top {
            padding-top: 55px;
        }
        .home-headline {
            margin-top: 0;
        }
    }
    .why-section .health-map img {
        max-width: 484px;
    }
    .how-section,
    .partners-section {
        background: $irricheck-secondary-colour;
    }
    .footer {
        background: #363839;
    }
}

@media screen and (min-width: 768px) {
    .landing-page,
    .citrus-campaign {
        .headline-section .home-headline {
            padding-right: 15px;
        }
    }
}

@media screen and (min-width: 1101px) {
    .landing-page,
    .citrus-campaign {
        .why-section .why-block .health-map {
            display: none;
        }
    }
}

@media screen and (min-width: 1800px) {
    .mielie-campaign {
        .headline-section {
            background-position: 50% -50px;
        }
    }
    .citrus-campaign {
        .headline-section {
            background-position: 50% -5em;
        }
    }
}

@media screen and (min-width: 2100px) {
    .mielie-campaign {
        .headline-section {
            background-position: 50% -110px;
        }
    }
    .citrus-campaign {
        .headline-section {
            background-position: 50% -11em;
        }
    }
}

@media screen and (max-width: 1600px) {
    .mielies-campaign.headline-section {
        background-size: cover;
    }
}

@media screen and (max-width: 1300px) {
    .mielie-campaign {
        .headline-section {
            background-position: 50% 110px;
        }
    }
}

@media screen and (max-width: 1100px) {
    .landing-page,
    .citrus-campaign {
        .headline-section {
            min-height: 450px;
            background-position: 50% 170px;
            .container.top {
                width: 100%;
            }
        }
        .why-section .about-me-list {
            text-align: left;
            display: inline-block;
            clear: both;
            margin-bottom: 15px;
        }
        .why-section .health-map {
            float: none;
            width: 100%;
            margin-bottom: 15px;
        }
        .why-section > .why > .health-map {
            display: none;
        }
        .container.why {
            width: 90%;
            padding-bottom: 10px;
        }
        .why-section {
            .why-block {
                text-align: center;
                width: 100%;
            }
        }
    }
    .mielies-b-campaign {
        .headline-section {
            min-height: 480px;
            background-position: 0;
        }
    }
    .citrus-campaign {
        .headline-section {
            background-position: 50% 0;
        }
    }
    .irricheck .headline-section {
        background-position: center center;
    }
}

@media screen and (max-width: 980px) {
    .landing-page,
    .citrus-campaign {
        padding-top: 0;
        .header-section {
            height: 50px;
            position: relative;
            .nav {
                float: right;
                margin-left: 0;
                margin-top: 3px;
                a {
                    padding: 15px 7px;
                    font-size: $small-text;
                }
            }
            a.questions-link {
                padding: 5px 12px;
                margin-top: -3px;
            }
        }
        .headline-section {
            .home-headline {
                margin-top: 0;
                width: 55%;
                strong:before {
                    display: none;
                }
            }
        }
    }

    .irricheck {
        .header-section {
            padding: 15px;
            height: 58px;
            .irricheck-logo {
                width: 140px;
                margin-top: -13px;
                margin-left: 20px;
            }
            .farmpin-wordmark {
                top: 17px;
                margin-left: 30px;
                font-size: 22px;
            }
        }
        .headline-section {
            .container.top {
                padding-top: 20px;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .irricheck {
        .header-section {

        }
    }
    .mielie-campaign {
        .headline-section {
            background-position: 50% 70px;
        }
    }
    .landing-page,
    .citrus-campaign {
        .container.why {
            width: 100%;
        }
        .headline-section {
            padding-bottom: 10px;
            .home-headline {
                text-align: center;
            }
            .home-headline,
            .form-block {
                float: none;
                width: 100%;
            }
        }
        .how-section {
            padding-top: 30px;
            padding-bottom: 0;
            .how-block {
                #map-prototype {
                    display: none;
                }
                h4, #down-arrow {
                    display: none;
                }
                .video {
                    width: 100%;
                    height: 300px;
                    img.kyk-my {
                        display: none;
                    }
                }
            }
        }
        .wat-kos-dit-section {
            padding-top: 40px;
            padding-bottom: 40px;
            .subtext {
                margin-bottom: 20px;
            }
            .price-panel {
                .left {
                    @include border-radius(6px 6px 0 0);
                }
                .left,
                .right {
                    float: none;
                    padding: 15px;
                    position: relative;
                    width: 100%;
                    .cta {
                        position: static;
                        display: block;
                        max-width: 250px;
                        margin: 20px auto 10px auto;
                    }
                    h4 {
                        text-align: center;
                        font-size: 21px;
                        line-height: 1.1em;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 750px) {
    .landing-page,
    .citrus-campaign {
        .header-section .nav {
            display: none;
        }
    }
}

@media screen and (max-width: 550px) {
    .landing-page,
    .citrus-campaign {
        .why-section .health-map {
            #heat-map {
                width: 122%;
                max-width: none;
                margin-left: -6%;
            }
        }
        .how-section {
            .how-block .video {
                height: 250px;
            }
        }
    }

    .citrus-campaign .why-section .health-map #heat-map {
        margin-left: -1%;
    }

    .mielies-campaign.headline-section {
        h2 {
            font-size: 19px;
            line-height: 25px;
        }
    }

    .mielies-b-campaign.headline-section {
        h2 {
            font-size: 19px;
            line-height: 25px;
        }
    }
}

@media screen and (max-width: 400px) {
    .campaign-form-block {
        width: 90%;
    }
    .irricheck .header-section {
        padding-left: 8px;
        padding-right: 8px;
        .irricheck-logo {
            width: 130px;
            margin: -12px -5px 0 0;
        }
        .farmpin-logo {
            height: 27px;
            margin-top: 1px;
        }
        .farmpin-wordmark {
            font-size: 18px;
            top: 17px;
            margin-left: 25px;
        }
    }
}